import React from "react";


/**
 * @author
 * @function Loader
 **/

const Loader = (props) => {
  return (
    <>
     <div className="loader-body col-sm-12 ">
     <i class="bi bi-arrow-repeat fs-3"></i>
      </div>
    </>
  );
};

export default Loader;
