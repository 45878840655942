import isEmpty from "is-empty";
import {useState,useContext,createContext} from "react";



function Service() {

  

  return (
    <>
      <div class="pagecontainerheader">
        <h3>Service</h3>
      </div> 
 
    <div class="container">

      <main role="main" class="col-md-12 pt-3 px-4">
        <h3>Service</h3>
        <p>
        RightPe is the online platform of Sunrise Infotech Services, a leading mobile payments and commerce platform in India. It started with online mobile recharge and bill payment and is today an online marketplace. In a very short span of time, we have expanded to more and more registered users. We strive to maintain an open culture where everyone is a contributor and feels comfortable sharing their ideas. Under this platform, RightPe offers its customers AEPS, Mobile & DTH recharge, Micro ATM, PAN Card, Recharge, Ayushman Card, Air & Bus Reservation, Bill Payment, Digital Many more services like Wallet are included.
        </p>
      </main>
    </div>
    </>
  );
}

export default Service;
