import React from "react";
import Banner from "./Banner";
import GameListScreen from "./GameListScreen";

/**
 * @author
 * @function Home
 **/

const Home = (props) => {
  return (
    <>
      <GameListScreen/>
    </>
  );
};

export default Home;
